type CounterCallback = (nextValue: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

export const decrement = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    count: number,
    steps: number,
    min: number,
    onDecrement: CounterCallback,
) => {
    const nextCount = count - steps;
    if (nextCount < min) {
        return;
    }
    return onDecrement(nextCount, event);
};

export const increment = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    count: number,
    steps: number,
    onIncrement: CounterCallback,
    max?: number,
) => {
    const nextCount = count + steps;
    if (max && nextCount > max) {
        return max;
    }
    return onIncrement(nextCount, event);
};
