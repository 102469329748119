import type { TravelFormRooms } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type { AllocationState } from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-types';

import React from 'react';

import { insertElementAt2DArrayIndex, updateElementAtIndex } from '@/core/utils/array';
import {
    getAdultsCount,
    getAdultsInRoom,
    getChildrenInRoom,
    isAdult,
    isChild,
} from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-service';
import {
    useCustomAllocationLayerOpen,
    useListOfChildren,
    usePreSelectedSelectedRoomsSet,
    useRoomState,
} from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-state';
import { TravelFormRoomElement } from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-types';

export default function useTravelFormRoomAllocation(
    preSelectedRooms: TravelFormRooms,
    maxAdultsPerRoom: number,
    maxChildrenPerRoom: number,
) {
    const [arePreSelectedRoomsSet, setPreSelectedRoomsSet] = usePreSelectedSelectedRoomsSet();
    const [roomState, setRoomState] = useRoomState();
    const [listOfChildren, setListOfChildren] = useListOfChildren();
    const [isCustomAllocationLayerOpen, setCustomAllocationLayerOpen] = useCustomAllocationLayerOpen();

    const activeRoomAllocationPlaceholder = React.useMemo(() => {
        if (roomState.length === 0) {
            return null;
        }

        const adults = roomState.flat().filter(isAdult).length;
        const children = roomState.flat().filter(isChild).length;
        const rooms = roomState.length;
        const adultsPlaceholder = adults ? `${adults} ${adults === 1 ? 'Erwachsener' : 'Erwachsene'} · ` : '';
        const childrenPlaceholder = children ? `${children} ${children === 1 ? 'Kind' : 'Kinder'} · ` : '';
        return `${adultsPlaceholder}${childrenPlaceholder}${rooms} Zimmer`;
    }, [roomState]);

    const adultsCount = getAdultsCount(roomState);
    const childrenCount = listOfChildren.length;
    const roomsCount = roomState.length;

    const allocation: AllocationState = {
        adultsCount,
        childrenCount,
        errors: {
            doChildrenWithoutAgeExist: listOfChildren.includes(TravelFormRoomElement.CHILD_WITHOUT_AGE),
            doChildrenWithoutAgeExistInCustomAllocation: roomState.some((room) =>
                room.includes(TravelFormRoomElement.CHILD_WITHOUT_AGE),
            ),
            doRoomsWithoutAdultsExist: roomState.some((room) => !room.includes(TravelFormRoomElement.ADULT)),
            exceedsMaxAdultsPerRoom: {
                errorText: 'Du möchtest mehr als 4 Erwachsene in 1 Zimmer buchen?',
                hintText: 'Erhöhe die Zimmeranzahl, um mehr als 4 Erwachsene hinzufügen zu können.',
                isError: Math.ceil(adultsCount / maxAdultsPerRoom) > roomsCount,
            },
            exceedsMaxChildrenPerRoom: {
                errorText: 'Du möchtest mehr als 4 Kinder in 1 Zimmer buchen?',
                hintText: 'Erhöhe die Zimmeranzahl, um mehr als 4 Kinder hinzufügen zu können.',
                isError: Math.ceil(childrenCount / maxChildrenPerRoom) > roomsCount,
            },
            minAdultsPerRoom: {
                errorText: 'Jede Buchung erfordert min. 1 Erwachsenen pro Zimmer.',
                hintText: 'Füge weitere Erwachsene hinzu oder reduziere die Zimmeranzahl',
                isError: roomsCount > adultsCount,
            },
        },
        listOfChildren,
        rooms: roomState,
        roomsCount,
    };

    const isValidAllocation =
        !allocation.errors.exceedsMaxAdultsPerRoom.isError &&
        !allocation.errors.exceedsMaxChildrenPerRoom.isError &&
        !allocation.errors.doChildrenWithoutAgeExist &&
        !allocation.errors.doRoomsWithoutAdultsExist &&
        !allocation.errors.minAdultsPerRoom.isError;

    const resetForm = () => {
        setRoomState(preSelectedRooms);
        setPreSelectedRoomsSet(false);
        syncListOfChildren(preSelectedRooms);
    };

    const allocateAge = (childIndex: number, value: number) => {
        const updatedListOfChildren = updateElementAtIndex(listOfChildren, childIndex, value);
        setListOfChildren(updatedListOfChildren);
        const updatedRoomState = roomState.map((room, index) => {
            const roomFilteredByAdults = getAdultsInRoom(room);
            return index === 0 ? [...roomFilteredByAdults, ...updatedListOfChildren] : roomFilteredByAdults;
        });
        handlePersonAllocation(updatedRoomState, 'child');
    };

    const allocateChildAgeInRoom = (
        roomIndex: number,
        adults: string[],
        children: number[],
        childIndex: number,
        value: number,
    ) => {
        const currentChildren = updateElementAtIndex(children, childIndex, value);
        setRoomState(updateElementAtIndex(roomState, roomIndex, [...adults, ...currentChildren]));
    };

    const addAdult = (roomIndex?: number) => {
        if (roomIndex !== undefined) {
            const updatedRoomState = insertElementAt2DArrayIndex(roomState, roomIndex, TravelFormRoomElement.ADULT);
            setRoomState(updatedRoomState);
            return;
        }

        const firstAllocatedRoom = [...roomState][0];

        /* create first room with adult */
        if (!firstAllocatedRoom) {
            setRoomState([[TravelFormRoomElement.ADULT]]);
            return;
        }

        const updatedRoomState = insertElementAt2DArrayIndex(roomState, 0, TravelFormRoomElement.ADULT);
        setRoomState(updatedRoomState);
        handlePersonAllocation(updatedRoomState, 'adult');
    };

    const removeAdult = (roomIndex?: number) => {
        if (roomIndex !== undefined) {
            const room = roomState[roomIndex] ?? [];
            const adultIndex = room.findIndex(isAdult);
            if (adultIndex !== -1) {
                room.splice(adultIndex, 1);
                setRoomState([...roomState]);
            }
            return;
        }

        let isAdultRemoved = false;
        const updatedRoomState = [...roomState].reverse().map((room) => {
            return room.flatMap((person) => {
                if (isAdult(person) && !isAdultRemoved) {
                    // eslint-disable-next-line fp/no-mutation
                    isAdultRemoved = true;
                    return [];
                }
                return person;
            });
        });

        setRoomState(updatedRoomState);
        handlePersonAllocation(updatedRoomState, 'adult');
    };

    const addChild = (roomIndex?: number) => {
        if (roomIndex !== undefined) {
            const currentRoom = roomState[roomIndex] ?? [];
            const updatedRoomState = roomState.map((room, index) =>
                index === roomIndex ? [...currentRoom, TravelFormRoomElement.CHILD_WITHOUT_AGE] : room,
            );
            setRoomState(updatedRoomState);
            return;
        }

        const updatedListOfChildren = [...listOfChildren, TravelFormRoomElement.CHILD_WITHOUT_AGE];
        setListOfChildren(updatedListOfChildren);
    };

    const removeChild = (roomIndex?: number) => {
        if (roomIndex !== undefined) {
            const room = roomState[roomIndex] ?? [];
            const childIndex = room.findIndex(isChild);
            if (childIndex !== -1) {
                room.splice(childIndex, 1);
                setRoomState([...roomState]);
            }
            return;
        }

        const updatedListOfChildren = [...listOfChildren];
        updatedListOfChildren.pop();
        setListOfChildren(updatedListOfChildren);
        const updatedRoomState = roomState.map((room, index) => {
            const resettedRoom = room.filter((person) => isAdult(person));
            return index === 0 ? [...resettedRoom, ...updatedListOfChildren] : resettedRoom;
        });
        handlePersonAllocation(updatedRoomState, 'child');
    };

    const addRoom = (type: 'default' | 'with-adult' = 'default') => {
        if (type === 'with-adult') {
            setRoomState([...roomState, [...TravelFormRoomElement.ADULT]]);
            return;
        }
        const updatedRoomState = [...roomState, []];
        setRoomState(updatedRoomState);
        handlePersonAllocation(updatedRoomState, 'all');
    };

    const removeRoom = (roomIndex?: number) => {
        if (roomIndex !== undefined) {
            const updatedRoomState = [...roomState];
            updatedRoomState.splice(roomIndex, 1);
            setRoomState(updatedRoomState);
            return;
        }

        const lastRoomIndex = [...roomState].length - 1;
        const lastRoom = [...roomState][lastRoomIndex];

        if (!lastRoom) {
            return;
        }

        if (lastRoom.length === 0) {
            const updatedRoomState = [...roomState];
            updatedRoomState.pop();
            setRoomState(updatedRoomState);
            return;
        }

        const updatedRoomState = [...roomState];
        updatedRoomState.pop();
        const newLastRoom = [...(updatedRoomState[updatedRoomState.length - 1] ?? []), ...lastRoom];

        const newRoomState = updatedRoomState.map((room, index) =>
            index === updatedRoomState.length - 1
                ? newLastRoom.sort((a) => (a === TravelFormRoomElement.ADULT ? -1 : 1))
                : room,
        );
        setRoomState(newRoomState);
        handlePersonAllocation(newRoomState, 'all');
    };

    const allocate = React.useCallback(
        (toAllocatePersons: (number | string)[], rooms: TravelFormRooms, type: 'adult' | 'child'): TravelFormRooms => {
            if (toAllocatePersons.length === 0) {
                return rooms;
            }

            const remainingPersons = [...toAllocatePersons];
            const allocatedRooms = rooms.map((room) => {
                const person = remainingPersons[0];
                remainingPersons.splice(0, 1);
                return !person ? [...room] : [...room, person];
            });

            return allocate(remainingPersons, allocatedRooms, type);
        },
        [],
    );

    const allocatePersons = React.useCallback(
        (toAllocateRoomstate: TravelFormRooms, type: 'adult' | 'child') => {
            const toAllocatePersons = toAllocateRoomstate.flat().filter((person) => {
                return type === 'adult' ? isAdult(person) : isChild(person);
            });

            const updatedFilteredByTypeRoomState = toAllocateRoomstate.map((room) =>
                room.filter((person) => (type === 'adult' ? isChild(person) : isAdult(person))),
            );

            const updatedRooms = allocate(toAllocatePersons, updatedFilteredByTypeRoomState, type);
            setRoomState(updatedRooms);
            return updatedRooms;
        },
        [allocate, setRoomState],
    );

    const handlePersonAllocation = (toAllocateRoomstate: TravelFormRooms, type: 'adult' | 'all' | 'child') => {
        if (type !== 'all') {
            allocatePersons(toAllocateRoomstate, type);
            return;
        }

        const updatedRooms = allocatePersons(toAllocateRoomstate, 'adult');
        if (!allocation.errors.doChildrenWithoutAgeExist) {
            setRoomState(allocatePersons(updatedRooms, 'child'));
        }
    };

    /* to sync auto room allocation list of children with custom room allocation list of children */
    const syncListOfChildren = (preSelectedRooms?: TravelFormRooms) => {
        const rooms = preSelectedRooms ?? roomState;
        setListOfChildren(getChildrenInRoom(rooms.flat()));
    };

    React.useEffect(() => {
        if (isCustomAllocationLayerOpen) {
            return;
        }
        if (!arePreSelectedRoomsSet || preSelectedRooms.length > 0) {
            setRoomState(preSelectedRooms);
            setListOfChildren(getChildrenInRoom(preSelectedRooms.flat()));
            setPreSelectedRoomsSet(true);
        }
    }, [
        arePreSelectedRoomsSet,
        isCustomAllocationLayerOpen,
        preSelectedRooms,
        setListOfChildren,
        setPreSelectedRoomsSet,
        setRoomState,
    ]);

    return {
        activeRoomAllocationPlaceholder,
        addAdult,
        addChild,
        addRoom,
        allocateAge,
        allocateChildAgeInRoom,
        allocation,
        isCustomAllocationLayerOpen,
        isValidAllocation,
        removeAdult,
        removeChild,
        removeRoom,
        resetForm,
        setCustomAllocationLayerOpen,
        syncListOfChildren,
    };
}
