import type { TravelFormRooms } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import { atom, useAtom } from '@/core/features/store/atom-store';

const arePreSelectedRoomsSetAtom = atom(false);
export const usePreSelectedSelectedRoomsSet = () => useAtom(arePreSelectedRoomsSetAtom);

const roomStateAtom = atom<TravelFormRooms>([]);
export const useRoomState = () => useAtom(roomStateAtom);

const listOfChildrenAtom = atom<number[]>([]);
export const useListOfChildren = () => useAtom(listOfChildrenAtom);

const isCustomAllocationLayerOpenAtom = atom(false);
export const useCustomAllocationLayerOpen = () => useAtom(isCustomAllocationLayerOpenAtom);
